<template lang="pug">
VCard
  VCardSubtitle.my-4 {{ book.name }}
  VCardTitle.text-h1(
    :class="{ 'text-primary': $vuetify.theme.name !== 'dark' }"
  ) {{ book.abbreviations[0] || "?" }}
  VCardSubtitle.mb-4 {{ i18n.t("ScriptureIndex.comments", { count: index.count }) }}
  VCardActions
    VBtn(
      :to="byChapterAction ? undefined : localePath(getScriptureIndexChaptersLocation(book.bid))"
      :color="$vuetify.theme.name === 'dark' ? 'primary-c050' : 'primary-c600'"
      variant="outlined"
      @click="onClickChapter"
    ) {{ i18n.t("ScriptureIndex.ByChapter") }}
    IndexStudyMenu(
      :commentary-type-counts="index.commentaryTypes"
      :range="book"
    )
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { type StandaloneBookData } from "@rsc/scripture-model";
import { type CommentaryIndexRangeCount } from "~/units/commentary/commentaryIndex";
import { getScriptureIndexChaptersLocation } from "~/routing/locations";

const localePath = useLocalePath();

interface Props {
  index: CommentaryIndexRangeCount<StandaloneBookData>;
  byChapterAction?: (bid: string) => void;
}
const i18n = useI18n();

const props = withDefaults(defineProps<Props>(), {});

const book = computed(() => props.index.range);

function onClickChapter() {
  if (props.byChapterAction) {
    props.byChapterAction(book.value.bid);
  }
}
</script>
